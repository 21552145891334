import React from 'react';
import { styled } from '@tmap/mmm-style-guide/src/styled';
import Header from '../components/header';
import Footer from '../components/footer';

const StyledBodyWrapper = styled('div')(({ theme, inset = 0 }) => ({
  background: 'white',
  paddingLeft: theme.spacing(inset),
  paddingRight: theme.spacing(inset),
  minHeight: 'calc(100vh - 72px)',
  [theme.breakpoints.down('sm')]: {
    paddingLeft: theme.spacing(inset / 2.0),
    paddingRight: theme.spacing(inset / 2.0),
  },
}));

function FullWrapper(props) {
  const {
    headerOffset = false,
    inset = 0,
    headerElevation,
    HeaderComponent = Header,
    children,
  } = props;
  return (
    <>
      <HeaderComponent elevation={headerElevation} />
      {headerOffset && (
        <div style={{ height: '36px', background: 'white' }} />
      )}
      <StyledBodyWrapper inset={inset}>
        {children}
      </StyledBodyWrapper>
      <Footer />
    </>
  );
}

export default FullWrapper;
