import React, { useEffect, useState } from 'react';

import imageUrlBuilder from '@sanity/image-url';
import { useRouter } from 'next/router';

import { Button } from '@tmap/mmm-style-guide/src/Button';
import { ArrowBack } from '@tmap/mmm-style-guide/src/Icon';
import { styled } from '@tmap/mmm-style-guide/src/styled';
import { Typography } from '@tmap/mmm-style-guide/src/Typography';
import client from '../client';
import Metatags from '../components/metatags';
import FullWrapper from '../layouts/fullWrapper';
import SplitFifty from '../layouts/splitFifty';

const builder = imageUrlBuilder(client);

const ErrorTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[400],
  fontWeight: 700,
}));

const PageHeader = styled(Typography)(({ theme }) => ({
  paddingTop: theme.spacing(3),
  fontWeight: 700,
}));

const PageCopy = styled(Typography)(({ theme }) => ({
  paddingBottom: theme.spacing(7),
}));

function NotFound() {
  const [doc, setSiteSettings] = useState(null);
  const router = useRouter();
  useEffect(() => {
    (async () => {
      const settings = await client.fetch('*[_id == "generalSiteSettings"][0]');
      setSiteSettings(settings);
    })();
  }, [setSiteSettings]);
  if (doc === null) return null;
  return (
    <>
      <Metatags
        title='404 Not Found'
        description={doc.notFoundTitle}
        canonical={`${process.env.NEXT_PUBLIC_CANONICAL_DOMAIN}/404`}
        image={builder.image(doc.notFoundImage).url()}
      />
      <FullWrapper>
        <SplitFifty mainImage={doc.notFoundImage}>
          <ErrorTitle variant='h5'>Error 404</ErrorTitle>
          <PageHeader variant='h3' component='h1' gutterBottom>{doc.notFoundTitle}</PageHeader>
          <PageCopy variant='body1'>{doc.notFoundBody}</PageCopy>
          <Button variant='contained' color='convert' onClick={() => router.back()}><ArrowBack />&nbsp;&nbsp;Go Back</Button>
        </SplitFifty>
      </FullWrapper>
    </>
  );
}

export default NotFound;
